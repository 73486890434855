import type { Money } from '@ecomm/models';
import { isGuideBundleTypeMatch } from '@ecomm/shop/models/Bundle';
import { BundleType } from '@ecomm/shop/models/BundleType';
import { maxTerm as affirmMaxTerm, Term as AffirmTerm } from '../affirm/term';
import type { Term as CitizensTerm } from '../citizens/term';
import { maxTerm as citizensMaxTerm } from '../citizens/term';
import { maxTerm as klarnaMaxTerm, Term as KlarnaTerm } from '../klarna/term';
import type { Term as PayBrightTerm } from '../paybright/term';
import { maxTerm as payBrightMaxTerm } from '../paybright/term';
import type { Term as ZipTerm } from '../zip/term';
import { maxTerm as zipMaxTerm } from '../zip/term';

import { Partners } from './Partners';

export type Term = AffirmTerm | CitizensTerm | KlarnaTerm | PayBrightTerm | ZipTerm;

export const maxTermByPartner = (
  financingPartner: Partners,
  isZipThirtySixMonthsFinancingEnabled: boolean,
  bundleType?: BundleType,
): Term => {
  const result = {
    [Partners.Affirm]: affirmMaxTerm(bundleType),
    [Partners.Citizens]: citizensMaxTerm(),
    [Partners.Klarna]: klarnaMaxTerm(bundleType),
    [Partners.Zip]: zipMaxTerm(isZipThirtySixMonthsFinancingEnabled),
    [Partners.PayBright]: payBrightMaxTerm(),
  };

  return result[financingPartner];
};

export const getMonthlyTotal = (total: Money, term: Term): Money =>
  Math.ceil(total / term);

type TermOptions = {
  partner: Partners;
  bundleType: BundleType;
};

/** @TODO clean up zipThirtySixMonthFinancing flag in downstream references. It's always true. */
export const defaultFinancingTerm = (
  { partner, bundleType }: TermOptions,
  isZipThirtySixMonthsFinancingEnabled: boolean,
) => {
  if (isGuideBundleTypeMatch(bundleType)) {
    switch (partner) {
      case Partners.Affirm:
        return AffirmTerm.TwentyFour;
      case Partners.Klarna:
        return KlarnaTerm.TwentyFour;
    }
  }

  return maxTermByPartner(partner, isZipThirtySixMonthsFinancingEnabled, bundleType);
};

type CartTermOptions = {
  partner: Partners;
  hasBikeInCart: boolean;
  hasTreadInCart: boolean;
  hasBikePlusInCart: boolean;
  hasGuideInCart: boolean;
  hasRowInCart: boolean;
  hasTreadPlusInCart: boolean;
};

type TBundleTypeFromCart = Omit<CartTermOptions, 'partner'>;

export const financingBundleTypeFromCart = ({
  hasTreadInCart,
  hasBikePlusInCart,
  hasBikeInCart,
  hasGuideInCart,
  hasRowInCart,
  hasTreadPlusInCart,
}: TBundleTypeFromCart): BundleType => {
  if (hasBikeInCart) {
    return BundleType.Bike;
  }
  if (hasBikePlusInCart) {
    return BundleType.BikePlus;
  }
  if (hasTreadInCart) {
    return BundleType.Tread;
  }
  if (hasRowInCart) {
    return BundleType.Row;
  }
  if (hasGuideInCart) {
    return BundleType.RainforestCafe;
  }
  if (hasTreadPlusInCart) {
    return BundleType.TreadPlus;
  }
  return BundleType.Bike;
};

export const financingTermFromCart = (
  {
    partner,
    hasTreadInCart,
    hasBikePlusInCart,
    hasBikeInCart,
    hasGuideInCart,
    hasRowInCart,
    hasTreadPlusInCart,
  }: CartTermOptions,
  isZipThirtySixMonthsFinancingEnabled: boolean,
) => {
  const bundleType = financingBundleTypeFromCart({
    hasTreadInCart,
    hasBikePlusInCart,
    hasBikeInCart,
    hasGuideInCart,
    hasRowInCart,
    hasTreadPlusInCart,
  });

  if (hasGuideInCart) {
    if (hasBikePlusInCart || hasTreadInCart || hasRowInCart) {
      return partner === Partners.Zip
        ? zipMaxTerm(isZipThirtySixMonthsFinancingEnabled)
        : AffirmTerm.FortyThree;
    } else if (hasBikeInCart) {
      return partner === Partners.Zip
        ? zipMaxTerm(isZipThirtySixMonthsFinancingEnabled)
        : AffirmTerm.ThirtyNine;
    } else {
      return AffirmTerm.TwentyFour;
    }
  }
  return maxTermByPartner(partner, isZipThirtySixMonthsFinancingEnabled, bundleType);
};
